import React, { useState } from 'react';
import './Header.css';
import logo from '../assets/logo.png'; 
import ContactCard from './ContactCard'; 
import AboutModal from './AboutModal'; 

const Header = () => {
  const [isContactOpen, setIsContactOpen] = useState(false);
  const [isAboutOpen, setIsAboutOpen] = useState(false); 

  const openContactModal = () => {
    setIsContactOpen(true);
  };

  const closeContactModal = () => {
    setIsContactOpen(false);
  };

  const openAboutModal = () => {
    setIsAboutOpen(true);
  };

  const closeAboutModal = () => {
    setIsAboutOpen(false);
  };

  const handleHomeClick = (event) => {
    event.preventDefault();
    window.scrollTo({
      top: 0,
      behavior: 'smooth'
    });
  };

  return (
    <header>
      <img src={logo} alt="Consultoria Certa Logo" className="logo" />
      <nav>
        <ul>
          <li><a href="#home" onClick={handleHomeClick}>Home</a></li>
          <li><button onClick={openAboutModal}>Sobre</button></li>
          <li><a href="#gallery">Galeria</a></li>
          <li><button onClick={openContactModal}>Contato</button></li>
        </ul>
      </nav>

      <ContactCard isOpen={isContactOpen} onRequestClose={closeContactModal} />

      <AboutModal isOpen={isAboutOpen} onRequestClose={closeAboutModal} />
    </header>
  );
};

export default Header;
