import React, { useState } from 'react';
import './Gallery.css';

import proj1Foto1 from '../assets/images/proj1.jpg';
import proj1Foto2 from '../assets/images/proj2.jpg';
import proj1Foto3 from '../assets/images/proj3.jpg';
import proj1Foto4 from '../assets/images/proj4.jpg';
import proj2Foto1 from '../assets/images/proj5.jpg';
import proj2Foto2 from '../assets/images/proj6.jpg';
import proj2Foto3 from '../assets/images/proj7.jpg';
import proj2Foto4 from '../assets/images/proj8.jpg';
import proj3Foto1 from '../assets/images/proj9.jpg';
import proj3Foto2 from '../assets/images/proj10.jpg';
import proj3Foto3 from '../assets/images/proj11.jpg';
import proj3Foto4 from '../assets/images/proj12.jpg';
import proj3Foto5 from '../assets/images/proj13.jpg';
import proj3Foto6 from '../assets/images/proj14.jpg';
import proj3Foto7 from '../assets/images/proj15.jpg';
import proj3Foto8 from '../assets/images/proj16.jpg';

const Gallery = () => {
  const [showAll, setShowAll] = useState(false);

  const projects = [
    { title: '', photos: [proj1Foto1, proj1Foto2, proj1Foto3, proj1Foto4] },
    { title: '', photos: [proj2Foto1, proj2Foto2, proj2Foto3, proj2Foto4] },
    { title: '', photos: [proj3Foto1, proj3Foto2, proj3Foto3, proj3Foto4] },
    { title: '', photos: [proj3Foto1, proj3Foto2, proj3Foto3, proj3Foto4] },
    { title: '', photos: [proj3Foto5, proj3Foto6, proj3Foto7, proj3Foto8] },
  ];

  const visibleProjects = showAll ? projects : projects.slice(0, 1);

  const handleViewMoreClick = () => {
    setShowAll(true);
  };

  const handleViewLessClick = () => {
    setShowAll(false);
  };

  return (
    <div id="gallery" className="gallery">
      <h2>Trabalhos Executados</h2>
      <div className="projects">
        {visibleProjects.map((project, projectIndex) => (
          <div key={projectIndex} className="project">
            <h3>{project.title}</h3>
            <div className="photos">
              {project.photos.map((photo, photoIndex) => (
                <img
                  key={photoIndex}
                  src={photo}
                  alt={`${project.title} - Foto ${photoIndex + 1}`}
                  className="gallery-image"
                />
              ))}
            </div>
          </div>
        ))}
      </div>
      {!showAll && (
        <button className="view-more" onClick={handleViewMoreClick}>
          Veja Mais
        </button>
      )}
      {showAll && (
        <button className="view-less" onClick={handleViewLessClick}>
          Veja Menos
        </button>
      )}
    </div>
  );
};

export default Gallery;
