import React from 'react';
import './ContactCard.css'; 

const ContactCard = ({ isOpen, onRequestClose }) => {
  if (!isOpen) {
    return null;
  }

  return (
    <div className="modal-overlay" onClick={onRequestClose}>
      <div className="modal-content contact-card" onClick={(e) => e.stopPropagation()}>
        <button className="close-button" onClick={onRequestClose}>&times;</button>
        <h2>Entre em Contato</h2>
        <div className="contact-info">
          <p><strong>E-mail:</strong> <a href="mailto:contato@consultoriacerta.com.br">contato@consultoriacerta.com.br</a></p>
          <p><strong>WhatsApp:</strong> <a href="https://wa.me/5517991113616" target="_blank" rel="noopener noreferrer">+55 (17) 99111-3616</a></p>
        </div>
      </div>
    </div>
  );
};

export default ContactCard;
