import React, { useState, useEffect } from 'react';
import './Banner.css';
import banner1 from '../assets/banner1.jpg';
import banner2 from '../assets/banner2.jpg';
import banner3 from '../assets/banner3.jpg';

const banners = [
  { id: 1, image: banner1, link: '/pagina1' },
  { id: 2, image: banner2, link: '/pagina2' },
  { id: 3, image: banner3, link: '/pagina3' }
];

const Banner = () => {
  const [currentBanner, setCurrentBanner] = useState(0);

  useEffect(() => {
    const interval = setInterval(() => {
      setCurrentBanner((prevBanner) => (prevBanner + 1) % banners.length);
    }, 5000);
    return () => clearInterval(interval);
  }, []);

  const goToPreviousBanner = () => {
    setCurrentBanner((prevBanner) => (prevBanner - 1 + banners.length) % banners.length);
  };

  const goToNextBanner = () => {
    setCurrentBanner((prevBanner) => (prevBanner + 1) % banners.length);
  };

  return (
    <div className="banner-container">
      <a href={banners[currentBanner].link}>
        <img src={banners[currentBanner].image} alt={`Banner ${currentBanner + 1}`} className="banner-image" />
      </a>
    </div>
  );
}

export default Banner;
