import React from "react";
import Header from "./components/Header";
import Banner from "./components/Banner";
import Section from "./components/Section";
import Gallery from "./components/Gallery";
import Footer from "./components/Footer";
import ClientsCarousel from "./components/ClientsCarousel";
import styled from 'styled-components';
import "./App.css";

const Main = styled.main`
  margin: 0 auto;
  padding: 20px;
  max-width: 1200px;
  min-height: 100vh;
  background-color: #f9f9f9;
  font-family: Arial, sans-serif;
  box-sizing: border-box;
`;

function App() {
  return (
    <div className="App">
      <Header />
      <Banner />
      <Section
        id="0"
        title="T.I sob medida"
        content="Projetos com o melhor aproveitamento dos cenários visando escalabilidade e custo benefícios, aliados a boas práticas."
      />
      <main className="main">
        <Section
          id="1"
          title="Missão"
          content="Projetos com o melhor aproveitamento dos cenários visando escalabilidade e custo benefícios, aliados a boas práticas."
        />
        <Section
          id="2"
          title="O que fazemos"
          content="Oferecemos consultoria especializada em tecnologia da informação para otimizar sua infraestrutura de TI e garantir que suas operações sejam executadas com eficiência. Nossos especialistas avaliam suas necessidades específicas e propõem soluções personalizadas para aprimorar seu ambiente tecnológico."
        />
        <Section
          id="3"
          title="Boas práticas"
          content="Adotamos as melhores práticas do setorpara assegurar a integridade, segurança e desempenho do seu sistema. Desde a configuração inicial até a manutenção contínua, seguimos rigorosos padrões para garantir que sua rede de computadores e equipamentos estejam sempre atualizados e funcionando de maneira ideal."
        />
        <Section
          id="4"
          title="Suporte"
          content="Nosso suporte técnico está disponível para resolver rapidamente qualquer problema que possa surgir. Com uma equipe de profissionais qualificados, garantimos assistência eficaz e soluções rápidas para minimizar o tempo de inatividade e manter sua operação fluindo sem interrupções."
        />
        <Section
          id="5"
          title="Projetos de infraestrutura"
          content="Desenvolvemos e implementamos projetos de infraestrutura de TI que atendem às suas necessidades específicas. Desde a instalação de redes de computadores até a configuração de câmeras de monitoramento, nossos projetos são projetados para oferecer escalabilidade e eficiência."
        />
        <Section
          id="6"
          title="Gerenciamento de projetos"
          content="Gerenciamos projetos de tecnologia com uma abordagem estruturada e orientada para resultados. Nosso gerenciamento inclui planejamento detalhado, execução eficiente e monitoramento constante para assegurar que seus projetos de TI sejam concluídos dentro do prazo e orçamento estabelecidos."
        />
      </main>
      <Gallery />
      <ClientsCarousel/>
      <Footer />
    </div>
  );
}

export default App;
