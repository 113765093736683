import React from 'react';
import './Section.css';

function Section({ id, title, content }) {

  if (id === '0') {
    return (
      <div id={id} className="section highlight-section">
        <h2 className="highlight-title">T.I sob medida</h2>
        <p className="highlight-content">
          Projetos com o melhor aproveitamento dos cenários visando escalabilidade e custo benefícios, aliados a boas práticas.
        </p>
      </div>
    );
  }

  return (
    <div id={id} className="section">
      <h2 className="section-title">{title}</h2>
      <p className="section-content">{content}</p>
    </div>
  );
}

export default Section;
