import React from 'react';
import Slider from 'react-slick';
import "slick-carousel/slick/slick.css"; 
import "slick-carousel/slick/slick-theme.css";
import './ClientsCarousel.css'; 

import client1 from '../assets/images/client1.png';
import client2 from '../assets/images/client2.png';
import client3 from '../assets/images/client3.png';
import client4 from '../assets/images/client4.png';
import client5 from '../assets/images/client5.png';
import client6 from '../assets/images/client6.png';
import client7 from '../assets/images/client7.png';
import client8 from '../assets/images/client8.png';

const ClientsCarousel = () => {

  const settings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 6,
    slidesToScroll: 1,
    autoplay: true,
    autoplaySpeed: 2000,
    responsive: [
      {
        breakpoint: 768,
        settings: {
          slidesToShow: 1,
        },
      },
    ],
  };

  return (
    <div className="clients-carousel">
      <h2>Nossos Principais Clientes</h2>
      <Slider {...settings}>
        <div className="client-slide">
          <img src={client1} alt="Cliente 1" />
        </div>
        <div className="client-slide">
          <img src={client2} alt="Cliente 2" />
        </div>
        <div className="client-slide">
          <img src={client3} alt="Cliente 3" />
        </div>
        <div className="client-slide">
          <img src={client4} alt="Cliente 4" />
        </div>
        <div className="client-slide">
          <img src={client5} alt="Cliente 5" />
        </div>
        <div className="client-slide">
          <img src={client6} alt="Cliente 6" />
        </div>
        <div className="client-slide">
          <img src={client7} alt="Cliente 7" />
        </div>
        <div className="client-slide">
          <img src={client8} alt="Cliente 8" />
        </div>
      
      </Slider>
    </div>
  );
};

export default ClientsCarousel;
