import React from 'react';
import './AboutModal.css'; 

const AboutModal = ({ isOpen, onRequestClose }) => {
  if (!isOpen) {
    return null;
  }

  return (
    <div className="about-modal-overlay" onClick={onRequestClose}>
      <div className="about-modal-content" onClick={(e) => e.stopPropagation()}>
        <button className="close-button" onClick={onRequestClose}>&times;</button>
        <h2>Sobre Nós</h2>
        <p>Fundados em 1997 somos especialistas em tecnologia da informação dedicados a otimizar sua infraestrutura de TI com soluções personalizadas que equilibram escalabilidade e custo-benefício. Adotamos as melhores práticas do setor para garantir a integridade, segurança e desempenho do seu sistema, desde a configuração inicial até a manutenção contínua. Oferecemos suporte técnico eficiente e rápido, minimizando o tempo de inatividade e mantendo suas operações sem interrupções. Além disso, desenvolvemos e implementamos projetos de infraestrutura de TI, como redes e sistemas de monitoramento, sempre com uma abordagem estruturada e orientada para resultados. Nosso gerenciamento de projetos é detalhado e focado em cumprir prazos e orçamentos estabelecidos.</p>
      </div>
    </div>
  );
};

export default AboutModal;
