import React from 'react';
import './Footer.css'; 
import logoFooter from '../assets/images/logo_Footer.png'; 
import qrCodeImage from '../assets/images/whatsapp-qrcode.png'; 

const Footer = () => {
  return (
    <footer className="footer">
      <img src={logoFooter} alt="Logo Footer" className="footer-logo" />

      <div className="footer-content">
        <div className="footer-socials">
          <a href="https://x.com/ConsultCertaTI" target="_blank" rel="noopener noreferrer" className="footer-social-link">
            X
          </a>
          
          <a href="https://www.instagram.com/consultoriacerta.ti/" target="_blank" rel="noopener noreferrer" className="footer-social-link">
            Instagram
          </a>

          <a href="https://facebook.com" target="_blank" rel="noopener noreferrer" className="footer-social-link">
            Facebook
          </a>
        
          <a href="https://linkedin.com" target="_blank" rel="noopener noreferrer" className="footer-social-link">
            LinkedIn
          </a>
        </div>

        <div className="footer-quote">
          <p className="quote-text">
            “Seja referência de qualidade. Algumas pessoas não estão acostumadas com um ambiente onde a excelência é esperada.”
          </p>
          <p className="quote-author">STEVE JOBS</p>
        </div>
      </div>

      <div className='dev'>
        <a href="https://github.com/m4riott0" target="_blank" rel="noopener noreferrer" className="footer-social-link">
        developed by: m4riott0
        </a>
      </div>

      <div className="footer-qr-container">
        <p className="qr-text">WhatsApp</p>
        <img src={qrCodeImage} alt="QR Code do WhatsApp" className="footer-qr" />
      </div>

    </footer>
  );
};

export default Footer;
